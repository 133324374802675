$weak-color: #ec9e01;

.pw-strength {
  display: block;
  width: 100%;
  &[data-pwstrength="EMPTY"] {
    .pw-strength__bar-fill {
      background-color: color('neutral-500');
      width: 0%;
    }
    .pw-strength__score {
      color: color('neutral-500');
    }
  }
  &[data-pwstrength="NOT_MET"] {
    .pw-strength__bar-fill {
      background-color: color('neutral-500');
      width: 15%;
    }
    .pw-strength__score {
      color: color('neutral-500');
    }
  }
  &[data-pwstrength="WEAK"] {
    .pw-strength__bar-fill {
      background-color: $weak-color;
      width: 33%;
    }
    .pw-strength__label {
      color: color('neutral-900');
    }
    .pw-strength__score {
      color: $weak-color;
    }
  }
  &[data-pwstrength="GOOD"] {
    .pw-strength__bar-fill {
      background-color: color('neutral-700');
      width: 55%;
    }
    .pw-strength__label {
      color: color('neutral-900');
    }
    .pw-strength__score {
      color: color('neutral-700');
    }
  }
  &[data-pwstrength="STRONG"] {
    .pw-strength__bar-fill {
      background-color: color('positive-text-color');
      width: 100%;
    }
    .pw-strength__label {
      color: color('neutral-900');
    }
    .pw-strength__score {
      color: color('positive-text-color');
    }
  }
  &__desc {
    display: block;
    margin-bottom: 15px;
  }
  &__bar {
    display: block;
    height: 10px;
    width: 100%;
    background-color: color('neutral-200');
    border-radius: 5px;
    position: relative;
    margin-bottom: 5px;
  }
  &__bar-fill {
    display: block;
    height: 100%;
    background-color: color('neutral-500');
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.3s;
  }
  &__score {
    font-weight: bold;
  }
}

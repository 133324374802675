// ProfileWelcomeMessage.jsx
@mixin profile-welcome-message() {
    .logged-in-pwm-block {
      .icon-block-icon {
        display: flex;
      }

      .icon {
        width: auto;
      }

      .signin-separator {
        margin-top:10px;
      }
    }
  }

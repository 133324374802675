/* Phone Number Modal styles */
#view-phonenumber-modal{
  .phone-numbers-info {
    .phoneList_row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      padding-bottom: 2rem;
      padding-top: 2rem;

      &:last-of-type {
        border-bottom: none;
      }
    }
    .countries-phone-numbers-info {
      display: flex;
      justify-content: center;
      padding-top: 15px;
    }
    @include media-query(1025) {
      .link-phoneNumber {
        pointer-events: none;
      }
    }
  }
}

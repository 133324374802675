.page-content-wrapper.picked-for-you {
    background-color: color('primary-light');
}

@include media-query(0, 540) {
    .page-content-wrapper.picked-for-you {
        padding: 40px 0;
    }
}

.picked-for-you-non-auth, .picked-for-you-non-perso-region {
    .button-group {
            min-width: 500px;
        }
        
    @include media-query(0, 540) {
        .button-group {
            min-width: 350px;
        }
    }

    .non-auth-header, .non-perso-region-header {
        .picked-for-you-header-container {
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        .picked-for-you-subtext {
            margin-top: 10px;
            text-align: center;
        }
        
        .sign-in-join-buttons, .shop-buttons {
            display: flex;
            justify-content: center
        }
    }
}

.picked-for-you-auth {
    margin: 0 auto;
    max-width: 1568px;
    padding: 0 25px;
    position: relative;

    .auth-header .picked-for-you-subtext {
        margin-top: 10px;
    }

    .recommendation-slider .headline {
        text-align: unset;
    }
}

.recommendation-slider {
    margin-top: 64px;

    .headline {
        margin-bottom: 25px;
    }
}
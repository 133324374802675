.my-info-block {
    .my-info-form {
      .account-form-group:nth-child(3) .account-form-content .input-field:nth-child(3) {
        display: flex;
      }
    }

    .info-banner-success-banner {
      position: relative;
      animation: fadeOut 5s forwards;
      .badge {
        position: absolute;
        top: -30px;
        height: 30px;
        width: 100%;
      }
    }
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
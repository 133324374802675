@mixin loading() {
    & > * > * {
      transition: opacity 200ms ease-in-out;
    }
  
    &[data-loading="true"] > * {
      animation: pulse-loading 1s infinite ease-out;
      background-color: color('primary-light');
      margin: 10px 0; // Separate loading blocks
      min-height: 50px; // Give minimum height
  
      &:first-of-type {
        margin-top: 0px;
      }
  
      &:last-of-type {
        margin-bottom: 0px;
      }
  
      & > * {
        opacity: 0;
      }
    }
  }
  
  .loading-animation {
    @include loading()
  }
  
  @keyframes pulse-loading {
    0% {
      background-color: color('secondary-light');
    }
  
    50% {
      background-color: color('primary-light');
    }
  
    100% {
      background-color: color('secondary-light');
    }
  }  
  
@import 'node_modules/anf-core/dist/1892/1892-framework';

// Component Styles go here
@import 'src/lib/components/SignIn/SignInToaster';
@import 'src/lib/components/ChangePasswordBlock/ChangePasswordModalContainer';
@import 'src/lib/components/CustomerCare/ViewPhoneNumberModule/PhoneNumberModal';
@import 'src/lib/components/SignIn/JoinForm';
@import 'src/lib/components/Common/KeepMeSignedIn/KeepMeSignedIn';
@import 'src/lib/components/SubscribeForm/SubscribeForm';
@import 'src/lib/components/SuccessModal/SuccessModal';

body {
  & > .modal.modal-z-index,
  & > .toaster .modal-z-index {
    z-index: 1001;
  }
}

// Positioning for Legal Modal Loader
.legal-modal-spinner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 30px;
  * {
    opacity: 1 !important;
  }
}

// Create a Password Modal - overriding z-index
@import 'src/lib/components/CreateAPasswordFlow/CreateAPasswordModal';
@import 'src/lib/components/CustomerSelfService/GiftCardBalanceSuccess';
@import 'src/lib/components/SignIn/SignInRewardsCommunicationModal';
@import 'src/lib/components/Common/PasswordStrengthMeter/PasswordStrengthMeter';

// loyalty specific
$loyalty-grey: #959698;
$loyalty-gold-1: #ED9A00;
$loyalty-gold-2: #FEBE20;
$loyalty-gold-3: #FDF3A4;

.points-history-container {
  .find-order-details {
    font-size: 1.8rem;
  }
  .points-history-find-order {
    text-align: left;
  }

  .points-list {
    &__records {
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 1;
      list-style: none;
      &:before {
        content: "";
        position: absolute;
        top: -30px;
        left: 29px;
        bottom: 0;
        border-left: 1px solid #dad9d0;
        z-index: -1;
      }
    }
  }

  .points-record {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2.5rem;
    &--points-lost {
      .points-record__balance {
        background-color: #c00;
      }
    }
    &--points-gained {
      .points-record__balance {
        background-color: #253746;
      }
    }
    &--gift-tier {
      .points-record__balance {
        background: linear-gradient(315deg, $loyalty-gold-1 0%, $loyalty-gold-2 37.31%, $loyalty-gold-3 100%);;
      }
    }
    &__balance {
      color: #FFF;
      font-family: font-family('trade-gothic-condensed');
      font-weight: 700;
      border-radius: 1rem;
      background-color: $loyalty-grey;
      min-width: 57px;
      padding: 2px 7px;
      text-align: center;
    }
    &__details {
      margin-left: 2rem;
      color: #4c4c4c;
    }
    &__description > * {
      display: block;
    }
    &__message {
      font-family: font-family('trade-gothic');
      font-weight: 700;
    }
  }

  // overrides
  .account-form,
  .missing-points-form {
    width: 100%;
  }

  .missing-points-form {
    .title,
    .description {
      text-align: left;
    }
  }

  .missing-points {
    .title,
    .description {
      text-align: left;
    }
    .title {
      font-size: 1.8rem;
    }

    .description {
      margin-top: 10px;
    }
  }
}
.marketing-preferences-form {
  .button-group {
    & > .button[data-variant="borderless"],
    & > .button[data-variant="secondary"] {
      flex-basis: 100%;
      max-width: 100%;
    }

    @include media-query(700) {
      & > .button[data-variant="borderless"],
      & > .button[data-variant="secondary"] {
        flex-basis: 50%;
        max-width: 50%;
      }
    }

    @include media-query(1200) {
      & > .button[data-variant="borderless"],
      & > .button[data-variant="secondary"] {
        flex-basis: 25%;
        max-width: 25%;
      }
    }
  }
}

@import './SignInTheme';

.authentication-form-inner-wrapper {
    background-color: map-get($authenticationForm, $brand, background-color);
}

@media only screen and (min-width: 360px) {
    .authentication-form-inner-wrapper {
        padding: map-get($authenticationForm, $brand, mobile-padding);
    }
}

@media  only screen and (min-width: 700px) {
    .authentication-form-inner-wrapper {
        padding: map-get($authenticationForm, $brand, desktop-padding);
    }
}

@media only screen and (min-width: 700px) {
    .authentication-form-outer-wrapper {
        margin: map-get($authenticationForm, $brand, margin);
        width: map-get($authenticationForm, $brand, width);
    }
}
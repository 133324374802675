@import './EmailUnsubscribeBlockProperties';

@mixin emailUnsubscribeBlock($brandVal: 'anf') {
  .email-unsubscribe-background {
    background-attachment: emailSubscribeBlockProperties('background-attachment');
    background-image: emailSubscribeBlockProperties('background-image');
    background-position: emailSubscribeBlockProperties('background-position');
    background-size: emailSubscribeBlockProperties('background-size'); 
  }

  .email-unsubscribe-container {
    margin: 0 auto;
    max-width: 475px;
  }
}

@include emailUnsubscribeBlock($brand);


@import './SignInTheme';

.rewards-communication-container .discount-card {
    margin: 15px 0 15px 0;
}

.rewards-communication {
    &__header {
        display: inline-grid;
    }

    &__button {
        margin-left: map-get($rewardsCommunicationModal, $brand, margin-left);
        width: map-get($rewardsCommunicationModal, $brand, width);
    }
}


.acct-left-nav {
  width:100%;
  padding-top: 70px;
  &[data-is-mobile='false'] {
    @include media-query(1025) {
      max-width: 250px;
      .acct-welcome-msg {
        padding: 56px 0 30px;
      }
    }
  }
  & > * {
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__content {
    padding: 20px 30px;
    text-align: center;
    &--full-width {
      padding: 20px 0;
    }
  }
  &__separator {
    border-style: none;
    border-bottom: 1px solid color('neutral-200');
  }
  &__title {
    text-decoration: none;
    color: color('navy-800');
  }
  &__button {
    margin-top: 25px;
  }
  // necessary for overflowing tabs scroll experience on mobile
  &__tabs-container {
    display: flex;
  }

  // overrides
  .acct-welcome-msg .icon-block .icon {
    width: auto;
  }
}

.acct-tabs {
  display: inline-flex;
  margin: 0 auto;
  overflow-x: scroll;
  overflow-y: visible;
  &[data-is-mobile='false'] {
    @include media-query(1025) {
      width: 100%;
      flex-direction: column;
      overflow-x: hidden;
      .acct-tabs__link {
        display: block;
        padding: 14px;
      }
    }
  }
  &__link {
    color: color('tab-links-text-color');
    display: flex;
    font-size: rem(15px);
    font-weight: 700;
    justify-content: center;

    // Due to rounding error, must use rems
    line-height: rem(22px);
    padding: 10px 15px 5px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    &:focus {
      @include focus-indicator(-5px);
      z-index: 1;
    }
    @include media-query(1025) {
      &:hover,
      &--selected {
        background-color: color('secondary-light');
      }
    }
    &--selected {
      $primary-dark: color('tab-link-selected-color-primary-dark');
      color: $primary-dark;
      @include type-style('tab-links-selected');
      @include media-query(0, 1025) {
        .acct-tabs__link-text {
          border-bottom: border-width('tab-links') solid $primary-dark;
          padding-bottom: 6px;
        }
      }

      @include media-query(1025) {
        border-left: 3px solid $primary-dark;
      }
    }
  }
  &__link-text {
    display: inline-block;
    padding: 0 10px;
  }
}

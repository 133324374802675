// NavigationLinks.jsx
@mixin navigation-links {
  .navigation-links {
    .link-arrow {
      visibility: hidden;
    }

    .icon-link-text {
      // Note: as of v7.129.0 linkText is required, but isn't bolded like the title
      font-weight: bold;

      > span:nth-of-type(2) {
        display: block;
        font-weight: 500;
      }
    }
  
    .link-button {
      text-transform: map-get($signInTheme, $brand, link-text-transform);
    }
  }
}

.account-form {
  [data-loading] {
    & > * {
      transition: opacity 0.5s ease-in; // 0.5s matches the DS transition for .account-form
    }
  }

  [data-loading="true"] {
    animation: pulse-loading 1s infinite ease-out;
    background-color: color('primary-light');
    margin: 10px 0; // Separate loading blocks
    min-height: 50px; // Give minimum height

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }

    & > * {
      opacity: 0;
      visibility: hidden;
    }
  }
}

@keyframes pulse-loading {
  0% {
    background-color: color('secondary-light');
  }

  50% {
    background-color: color('primary-light');
  }

  100% {
    background-color: color('secondary-light');
  }
}

@mixin _media-query($min, $max: 0) {
  @if $max > 0 {
    @media screen and (min-width: ($min * 1px)) and (max-width: ($max * 1px)) {
      @content;
    }
  } @else {
    @media screen and (min-width: ($min * 1px)){
      @content;
    }
  }
}

// Generates a-xs-1 like classes
@mixin _accountBlockGrid-size($size, $low) {
  @include _media-query($low) {
    @for $i from 1 through 8 {
      > .a-#{$size}-#{$i} {
        width: calc(100% / 8 * #{$i});
      }
    }
  }
}

// updated to main consistency with crs accountBlock
.a-row {
  @include _accountBlockGrid-size('xs', 0);
  @include _accountBlockGrid-size('sm', 360);
  @include _accountBlockGrid-size('smd', 540);
  @include _accountBlockGrid-size('md', 700);
  @include _accountBlockGrid-size('xmd', 825);
  @include _accountBlockGrid-size('lg', 1025);
  @include _accountBlockGrid-size('xl', 1200);
  @include _accountBlockGrid-size('xxl', 1500);

  & {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
  }

  & > * {
    padding: 10px;
  }

  @include _media-query(360) {
    margin: -10px -12.5px;

    & > * {
      padding: 10px 12.5px;
    }
  }

  @include _media-query(700) {
    margin: -20px;

    & > * {
      padding: 20px;
    }
  }
}
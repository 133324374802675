@import './CustomerSelfServiceTheme';
.self-service-account-block {
  @media only screen and (min-width: 700px) {
    width: map-get($homePageMain, $brand, width);
    margin: map-get($homePageMain, $brand, margin);
  }
  .heading,
  .content {
    background-color: #f6f6f6;
    margin: map-get($accountContent, $brand, margin);
  }
  .section-heading {
    margin-left: 0;
    margin-right: 0;
  }
    
  .tooltip-widget {
    display: inline-block;
    position: absolute;
    right: 0px;

    & > .tooltip-content { 
      width: 200px;
    }
  }
    
  .button-group[data-variant="horizontal-spaced"] {
    min-width: 700px;
    border-color: black;
    background-color: map-get($homePageMain, $brand, background-color);
      
    & > .button {
      padding: 25px;
      margin: -0.5px;
      flex-basis: 50%;
      max-width: 50%;
      border-radius: map-get($homePageMain, $brand, border-radius);
    }
      
    & > .button:not(:first-child):not(:last-child) {
      border-radius: map-get($homePageMain, $brand, border-radius);
    }
  }

  .form-help-container {
    background-color: map-get($custSelfServiceForm, $brand, background-color);
    padding: map-get($formContainer, $brand, padding);;
    border: map-get($custSelfServiceForm, $brand, border);
    margin-bottom: 25px;

    @media only screen and (min-width: 700px) {
      background-color: map-get($custSelfServiceForm, $brand, background-color);
      margin: 5% 10% 5% 10%;
      padding: map-get($formContainerMobile, $brand, padding);
    }
  }

  .view-order-history {
    margin-bottom: 22px; 
      .view-order-history-header{
        margin-bottom: 21px;
      }
  }

  .additional-info-section {
    margin-top: 15px;
      @media only screen and (min-width: 700px) {
        margin: 3% 10% 0 10%
      }
  }

  .additional-info-content p {
    margin-top: 5px;
    position: relative;
    padding-left: 20px;

    & > a {
      font-weight: bold;
    }
    @media only screen and (min-width: 700px) {
      font-size: 15px;
    }
  }
    
  .additional-info-content p::before {
    content: '•'; /* Unicode to display the content in bullet points */
    position: absolute;
    left: 0;
    color: black;
  }
}

.self-service-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  @media only screen and (min-width: 700px) {
    position: relative;
    padding-right: 55px;
    height: 90px;
    text-align: left;
    .icon {
      position: absolute;
      top: 50%;
      right: -5px;
      transform: translateY(-50%);
    }
  }
}

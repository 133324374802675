.payment-data-card {
  &__info {
    display: flex;
  }
  &__icon {
    margin-top: 4px;
  }
  &__desc {
    font-size: 11px;
    margin-left: 10px;
  }
  &__ctas {
    margin-top: 25px;
  }
  &__hr {
    margin-bottom: 15px;
  }
  &__default-status {
    .icon-block-icon,
    .h3 {
      color: color('green-500');
    }
  }
}

.write-a-review-loyalty-card {
  .write-a-review-next-reward-until {
    display: flex;
    justify-content: center;
    font-size: 15px;
    .icon-block-icon {
      color: #B08536;
    }
  }

  .write-a-review-points-desc {
    display: flex;
    justify-content: center;
    gap: 28px;
  }

  .points-for-review-block,
  .points-for-adding-photo-block {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

.review-items-header-container {
  margin-bottom: 15px;
  margin-top: 32px;

  .review-items-header-subtext{
    margin-top: 7px;
  }
}

.review-items-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .account-form-content {
      flex: 1 1 calc(50% - 10px);
  
      .productcard-header {
        padding-right: 0;
      }
  
      @media screen and (min-width: 700px) {
        max-width: calc(50% - 10px);
      }
  
      .productcard {
        border: none;
      }
  
      .product-detail {
        span {
          text-transform: capitalize;
        }
      }
  
      .productcard-description {
        button {
          text-transform: none;
        }
      }
    }

  .review-product-card {
    .review-item-submitted-note {
      .icon-block-icon {
        margin-right: 4px;
      }
      .icon-block-content {
        width: max-content
      }
    }
    .product-image {
      @media screen and (max-width: 700px) {
        width: 135px;
      }
    }
  }
}

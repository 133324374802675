@import './UpperFunnelTheme';

#upperfunnel-toaster {
  background-color: map-get($upperfunnelTheme, $brand, background-color);
  border-radius: map-get($upperfunnelTheme, $brand, border-radius);
  box-shadow: map-get($upperfunnelTheme, $brand, box-shadow);
  padding: 20px;
  position: fixed;
  right:0;
  top:150px;
  width: 320px;
  z-index: 9999;

  // remove this when we use DS IconButton.
  .icon-button {
    background-color: map-get($upperfunnelTheme, $brand, background-color);
    position: absolute;
    right:15px;
    top:15px;
    span {
     font-size: 20px;
    }
  }

  .upperfunnel-content{
    margin: map-get($upperfunnelTheme, $brand, margin);
    text-transform: map-get($upperfunnelTheme, $brand, text-transform);
    @media screen and (max-width: 700px) {
      margin: 5px;
    }
  }

  .ufl-hhr-image-large {
    background-image: url(https://img.abercrombie.com/is/image/anf/HHR-Logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin: 25px 10px -15px -15px;
    min-height: 64px;
  }

  @media screen and (max-width: 700px) {
    bottom: 0;
    left: 50%;
    position: fixed;
    top: auto;
    transform: translateX(-50%);
    width: 280px;
    z-index: 9999;
  }
}

@import "./ContactUsBlockTheme";

.contactUs-container {
  background-color: map-get($contactusblockTheme, $brand, background-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
}

.contactUs-container[data-orientation="horizontal"] .cust-care-block:nth-child(1):nth-last-child(2),
.contactUs-container[data-orientation="horizontal"] .cust-care-block:nth-child(2):nth-last-child(1) {
  width: 50%; /* if there are 2 divs, make them 1/2 of the container width */
}
.contactUs-container[data-orientation="horizontal"] .cust-care-block:nth-child(1):nth-last-child(3),
.contactUs-container[data-orientation="horizontal"] .cust-care-block:nth-child(2):nth-last-child(2),
.contactUs-container[data-orientation="horizontal"] .cust-care-block:nth-child(3):nth-last-child(1) {
  width: 33%; /* if there are 3 divs, make them 1/3 of the container width */
}
.contactUs-container[data-orientation="horizontal"] .cust-care-block:nth-child(1):nth-last-child(4),
.contactUs-container[data-orientation="horizontal"] .cust-care-block:nth-child(2):nth-last-child(3),
.contactUs-container[data-orientation="horizontal"] .cust-care-block:nth-child(3):nth-last-child(2),
.contactUs-container[data-orientation="horizontal"] .cust-care-block:nth-child(4):nth-last-child(1) {
  width: 25%; /* if there are 4 divs, make them 1/4 of the container width */
}

.cust-care-block {
  width: 65vw;
  margin: 15px 0px 15px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.customer-care-button {
  width: 100%;
  max-width: 300px;
}

.cust-care-icon {
  background-color: map-get($contactusblockTheme, $brand, background-color);
  color: map-get($contactusblockTheme, $brand, color);
  margin-right: 30px;
}

.whatsapp-button {
  box-sizing: border-box;
}

.whatsapp-label {
  color: #fff;
  font-size: 1.3rem;
  font-family: "DS Trade Gothic", "Trade Gothic", sans-serif;
  font-weight: 700;
  letter-spacing: 0.04rem;
  line-height: 1.5384615385;
  min-height: 50px;
  padding: 3px 15px;
  margin: 10px 0px 0px 0px;
}

@media only screen and (min-width: 700px) { // 700 px and wider
  .contactUs-container {
    flex-direction: row;
  }

  .cust-care-block {
    flex-direction: column;
  }

  .cust-care-icon {
    margin-right: 0px;
  }

  .customer-care-button {
    width: 90%;
    max-width: 200px;
    margin: 10px 0px 0px 0px;
  }

  .whatsapp-label {
    margin: 10px -5px 0px -5px;
  }
}

.address-data-card {
  &__ctas {
    margin-top: 25px;
  }
  &__hr {
    margin-bottom: 15px;
  }
  &__default-status {
    .icon-block-icon,
    .h3 {
      color: color('green-500');
    }
  }
}

.slick-styles {
  @import 'slick-carousel/slick/slick.css';
  @import 'slick-carousel/slick/slick-theme.css';
}

$createPasswordModalTheme: (
  'anf': (
    color: color('primary-light'),
  ),
  'kids': (
    color: color('primary-light'),
  ),
  'hol': (
    color: color('error-color'),
  )  
);

#create-a-password-modal,
.modal-z-index  {
    z-index: 1007;
}

// set z-index for legal modals
.modal[id*="LEGAL_"].modal-z-index {
  z-index: 1008;
}

// react-slick's adaptiveHeight is broken, this is a workaround
.slick-slide {
  height: 0px !important;
}
.slick-slide.slick-active {
  height: 100% !important;  
}

.slick-list {
  transition: height 500ms linear;
}

.link-error-message {
  font-weight: 700 !important;
  color: map-get($createPasswordModalTheme, $brand, color) !important;
  padding-left: 6px;
}

.myRewards-container {
  margin: 0 0 30px 0;
  @media only screen and (min-width: 700px) {
    margin: 0 30px 30px 30px;
  }
  .h1 {
    text-align: center;
  }

  .discount-list {
    @media only screen and (min-width: 1025px) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: center;
    }

    .discount-card {
      .discount-content {
        margin: 15px;
        @media only screen and (max-width: 1025px) {
          margin: 10px 0;
        }
      }

      @media only screen and (min-width: 1025px) {
        width: calc(50% - 30px);
      }
    }

    .discount-content .discount-status {
      border-left: none;
      border-left-style: solid;
      border-left-width: 1px;
      border-color: #ccc;
      width: auto
    }
  }
}
.loyalty-card-container {
  .loyalty-card {
    .loyalty-body {
      padding: 22px 0;

      .loyalty-progress-bar-label {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
      }
    }
  }

  .data-card-header {
    p {
      font-size: 1.8rem;
      font-weight: 400;
    }
  }
}

@media only screen and (min-width: 700px) {
  .loyalty-card {
    min-width: 370px;
  }
}
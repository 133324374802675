@import './EmailSubscribeBlockProperties';

@mixin emailSubscribeBlock($brandVal: 'anf') {
  .email-subscribe-background {
    background-attachment: emailSubscribeBlockProperties('background-attachment');
    background-image: emailSubscribeBlockProperties('background-image');
    background-position: emailSubscribeBlockProperties('background-position');
    background-size: emailSubscribeBlockProperties('background-size');
  }

  .email-subscribe-container {
    margin: 0 auto;
    max-width: 475px;
  }
}

@include emailSubscribeBlock($brand);

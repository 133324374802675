@import './SignInTheme';
@import './NavigationLinks';
@import './ProfileWelcomeMessage';

@include navigation-links();
@include profile-welcome-message();

// SignInBlock.jsx
.signin-block {
  .loading .spin-container {
    opacity: 1;
  }

  .button[data-variant="borderless"] {
    padding-left: map-get($signInTheme, $brand, signin-button-padding-left);
    padding-right: map-get($signInTheme, $brand, signin-button-padding-right);
    color: map-get($signInTheme, $brand, signin-button-color);
  }

  .vertical-separator {
    align-items: center;

    .button[data-variant="borderless"] {
      min-height: map-get($signInTheme, $brand, signin-button-min-height);
    }
  }

  @include media-query(1025) {
    padding-right: 25px;
  }

  // Hide on desktop, but show uf-prompt
  @include media-query(0, 1025) {
    display: none;
  }
}

// SignInMenu.jsx
.signin-menu {
  background-color: map-get($signInTheme, $brand, signin-menu-background-color);

  .join-link-container {
    text-align: center;
    .link-button {
      text-decoration: none;
    }
  }

  .discount-card:not(:last-of-type) {
    margin-bottom: 10px;
  }
  #rewards-coupons-panel-id {
    .accordion-panel-content {
      padding-bottom: 0px;
    }

    .rewards-bday-points-description {
      margin: 10px 0 5px 0;
      color: color('primary-dark');
    }
  }

  #rewards-coupons.accordion-trigger,#rewards-coupons-panel-id.accordion-panel {
    background: map-get($signInTheme, $brand, signin-menu-background-color);
  }
  #rewards-coupons{
    .rewards-accordion-coupons {
      padding-top:18px;
    }
  } // id=`rewards-coupons` needs to be removed when we clean up the rewards accordion.
  .rewards-coupons {
    padding-top:18px;
  }
}

// LoggedInButton.jsx
.logged-in-sign-in-block {
  img.icon {
    display: flex;
  }
}

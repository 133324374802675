@import "./LoyaltyBlockTheme";

.loyalty-block-container {
  margin: 20px;
  min-height: 500px;

  @include media-query(700) {
    max-width: 1250px;
    margin: 20px auto;
  }

  .loyalty-welcome-message { 
    @media screen and (max-width: 699px) {
      display: none;
    }
  }

  .loyalty-welcome-message-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .header {
      display: flex;
      align-items: center;
    }

    .welcome-message {
      margin-left: 12px;
      font-size: 22px;
      text-transform: uppercase;
    }
  }

  .loyalty-card-container {
    margin: 0px;

    @include media-query(700) {
      margin: 0 20px 0 25px;
    }
  }

  .rewards-container {
    margin-top: 45px;
  }

  .cross-border-rep {
    &__info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
    &__actions {
      margin-top: 30px;
    }
  }

  // overwrites
  .data-card {
    padding: 30px 20px;
    height: 260px;

    @include media-query(700) {
      min-width: 580px;
      padding: 40px 64px;
    }

    .loyalty-footer {
      display: flex;

      .points-history-btn {
        flex: auto;
      }

      .vip-loyalty-footer-container {
        display: flex;
        flex-direction: column;

        @include media-query(700) {
          flex-direction: row;
          gap: 20px;
          margin-top: 18px;
        }
      }
    }
  }

  .account-block {
    .heading,
    .content {
      background-color: transparent;
    }
    .section-heading {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.switch-account-form {
  text-align: center;

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: -20px;
  }

  &__decline {
    margin: 20px auto;
  }
}

@mixin loyaltyWelcomeMessage($brandVal: "anf") {
  .loyalty-welcome-message-container {
    border-bottom: loyaltyBlockProperties("border-bottom");

    .header {
      flex-direction: loyaltyBlockProperties("flex-direction");
      font-weight: loyaltyBlockProperties("font-weight");
    }

    .welcome-message {
      text-transform: loyaltyBlockProperties("text-transform");
    }

    .loyalty-img {
      margin-bottom: loyaltyBlockProperties("img-margin-bottom");
    }
  }

  .myPointsProgressBar {
    progress {
      height: loyaltyBlockProperties("my-points-progress-bar-height");
      overflow: hidden;
    }
    progress::-webkit-progress-bar {
      height: loyaltyBlockProperties("my-points-progress-bar-height");
      border-radius: loyaltyBlockProperties("my-points-progress-bar-height") / 2;
    }
    progress::-webkit-progress-value {
      background-color: loyaltyBlockProperties("my-points-progress-bar-color");
      border-radius: loyaltyBlockProperties("my-points-progress-bar-height") / 2;
    }
  }

  .vipProgressBar {
    progress {
      height: loyaltyBlockProperties("vip-progress-bar-height");
      overflow: hidden;
    }
    progress::-webkit-progress-bar {
      height: loyaltyBlockProperties("vip-progress-bar-height");
      border-radius: loyaltyBlockProperties("vip-progress-bar-height") / 2;
    }
    progress::-webkit-progress-value {
      background-color: loyaltyBlockProperties("vip-progress-bar-color");
      border-radius: loyaltyBlockProperties("vip-progress-bar-height") / 2;
    }
  }
  .loyalty-block-container {
    .data-card-header {
      text-transform: loyaltyBlockProperties("data-card-header-text-transform");
    }
  }
}

@include loyaltyWelcomeMessage($brand);

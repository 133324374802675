.gc-balance-success {
    max-width: 360px;
  
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
  
      .icon {
        margin-bottom: 15px;
        color: color('primary-dark');
      }
    }
  
    &__title {
      margin-bottom: 10px;
    }
  
    &__desc {
      color: color('float-label-text-color');
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  
  .gift-card-account-number-field .tooltip-widget {
    display: inline-block;
    position: absolute;
    right: 0px;
  }
  
.order-history {
  .transaction-summary {
    width: 100%;
  }

  .tab-panel {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .account-block {
    padding-top: 20px;
    padding-bottom: 20px
  }

  // overriding a legacy wcs rule from global.css
  .button-group {
    & > .button {
      margin: 0;
    }
  }

  .return-and-exchange-policy{
    margin-top: 8px;
  }
}

@import './SignInTheme';
@import './NavigationLinks';
@import './ProfileWelcomeMessage';

#signin-toaster {
  .signin-menu {
    @include navigation-links();
    @include profile-welcome-message();

    .join-link-container {
      text-align: center;
      .link-button {
        text-decoration: none;
      }
    }

    .discount-card:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  /* ===== overwrites ===== */
  // DS v7.110.2: .modal-heading has min-height=inherit, get's the min-height 100% from .modal-block
  &.modal[data-variant="headerless"] .modal-heading {
    min-height: 0;
  }

  .modal-block {
    background-color: map-get($signInTheme, $brand, signin-menu-background-color);
    margin-top: 0;
    max-width: 450px;
    min-height: 100vh;
  }

  .accordion-trigger,
  .accordion-panel {
    background: map-get($signInTheme, $brand, signin-menu-background-color);
    padding: 18px 0px 0px 0px;
  }

  .accordion-panel-content {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    .discount-card {
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .rewards-bday-points-description {
      margin: 10px 0 5px 0;
      color: color('primary-dark');
    }
  }

  #rewards-coupons-panel-id {
    .accordion-panel-content {
      padding-bottom: 0px;
    }
  }

  #rewards-coupons-panel-id.accordion-panel{
    padding-top: 0px;
  }
  
  #rewards-coupons-panel-id {
    .rewards-accordion-coupons {
      padding-top: 18px
    }
  }

  //styles for account flyout recommendation slider
  #account-flyout-1 .recommendation-slider {
    [class*="slider-module__control-container"] {
      display: none;
    }

    [class*="slider-module__slide"] {
      outline: none;
    }

    .product-content {
      padding-top: 5px;

      .product-price {
        display: none;
      }
    }

    .headline {
      box-sizing: border-box;
      font-family: map-get($recommendationSlider, $brand, font-family);
      font-size: 1.5rem;
      font-weight: map-get($recommendationSlider, $brand, font-weight);
      letter-spacing: map-get($recommendationSlider, $brand, letter-spacing);
      margin-bottom: 0;
      margin-left: 10px;
      padding: 0 0 .5rem;
      text-align: left;
    }
  }

  /*
    Override the account grid recs headline styling for the grid within the
    account toaster.

    Jira: https://anfcorp.atlassian.net/browse/ACCT-418
  */
  [data-recommendations-client] {
    .headline {
      margin: 30px 0;
      font-size: 2.5rem;
    }
  }
}

/*
  Note: as per v7.123.0 and greater the transform on the translate out is not actually being added
  to the modal-block. This is causing a flash (onClose) of the toaster in an open state

  TODO: remove this code once toaster is updated within anf-core-react
*/
.toaster-out[data-direction="from-right"] #signin-toaster .modal-block {
  transform: translate(100%);
}

// This fixes sign in modal popping up behind QV.
#authentication-modal {
  z-index: 1006;
}